import React from 'react';

import { Feature, Media } from '../../../models/Strapi';
import FeatureList from '../../../molecules/StrapiComponent/FeatureList/FeatureList';
import { UrlService } from '../../../services/UrlService';
import styles from './InfoModule.css';

type AnnualFeature = {
    icon: Media;
    label: string;
};

type Component = {
    id: number;
    sideImage: Media;
    annualFeatures: AnnualFeature[];
    featureLabel: string;
    features: Feature[];
};

type GameInfoModuleProps = {
    title: string;
    component: Component[];
    background: Media;
    backgroundFallback: string;
};

const GameInfoModule = React.memo((props: GameInfoModuleProps) => {
    const {
        title,
        component: [infoComponent],
    } = props;

    const getBackground = ({ background, backgroundFallback }: GameInfoModuleProps) => {
        const result = {} as React.CSSProperties;
        if (background) {
            result.backgroundImage = `url(${UrlService.createStrapiMediaUrl(background.url)})`;
        }
        result.backgroundColor = backgroundFallback;
        return result;
    };

    return (
        <div className={styles.content} style={getBackground(props)}>
            <section className={styles.container}>
                <h2 className={styles.mainTitle}>{title}</h2>
                <div className={styles.columns}>
                    <img
                        src={UrlService.createStrapiMediaUrl(infoComponent.sideImage.url)}
                        alt={infoComponent.sideImage.alternativeText}
                    />
                    <span className={styles.annualFeatures}>
                        {infoComponent.annualFeatures.map((annualFeature) => (
                            <div key={annualFeature.label} className={styles.annualFeature}>
                                <img
                                    src={UrlService.createStrapiMediaUrl(annualFeature.icon.url)}
                                    alt={annualFeature.icon.alternativeText}
                                />
                                {annualFeature.label}
                            </div>
                        ))}
                    </span>
                    <span className={styles.featureContainer}>
                        <p>{infoComponent.featureLabel}</p>
                        <FeatureList features={infoComponent.features} />
                    </span>
                </div>
            </section>
        </div>
    );
});

export default GameInfoModule;
